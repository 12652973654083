import * as React from 'react'

const IconClose: React.FC<{ size?: number; color?: string }> = ({ size = 24, color = '#212529' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.56588 5.43433C6.25346 5.12191 5.74693 5.12191 5.43451 5.43433C5.12209 5.74675 5.12209 6.25328 5.43451 6.5657L10.8688 12L5.43451 17.4343C5.12209 17.7467 5.12209 18.2533 5.43451 18.5657C5.74693 18.8781 6.25346 18.8781 6.56588 18.5657L12.0002 13.1314L17.4345 18.5657C17.7469 18.8781 18.2535 18.8781 18.5659 18.5657C18.8783 18.2533 18.8783 17.7467 18.5659 17.4343L13.1316 12L18.5659 6.5657C18.8783 6.25328 18.8783 5.74675 18.5659 5.43433C18.2535 5.12191 17.7469 5.12191 17.4345 5.43433L12.0002 10.8686L6.56588 5.43433Z"
        fill={color}
      />
      <path
        d="M6.56588 5.43433C6.25346 5.12191 5.74693 5.12191 5.43451 5.43433C5.12209 5.74675 5.12209 6.25328 5.43451 6.5657L10.8688 12L5.43451 17.4343C5.12209 17.7467 5.12209 18.2533 5.43451 18.5657C5.74693 18.8781 6.25346 18.8781 6.56588 18.5657L12.0002 13.1314L17.4345 18.5657C17.7469 18.8781 18.2535 18.8781 18.5659 18.5657C18.8783 18.2533 18.8783 17.7467 18.5659 17.4343L13.1316 12L18.5659 6.5657C18.8783 6.25328 18.8783 5.74675 18.5659 5.43433C18.2535 5.12191 17.7469 5.12191 17.4345 5.43433L12.0002 10.8686L6.56588 5.43433Z"
        fill={color}
      />
    </svg>
  )
}

export default React.memo(IconClose)
